import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { twMerge } from "tailwind-merge";
import Text, { TextVariant } from "../Text";
import { useMediaQuery } from "react-responsive";

interface CustomerList {
  fullList?: boolean;
  className?: string;
}

const CustomerList = ({ fullList = false, className }: CustomerList) => {
  const [section, setSection] = React.useState<"company" | "school">("company");
  const imageWrapperClassName = "flex items-center justify-center h-36";
  const imageClassName = "lg:w-52 h-36";

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  const displayEntrepriseSection = () => setSection("company");
  const displaySchoolSection = () => setSection("school");

  return (
    <div className={className}>
      <div className="lg:hidden">
        <div className="flex flex-row font-poppins">
          <div
            className="flex items-center justify-center w-1/2"
            onMouseEnter={displayEntrepriseSection}
            onClick={displayEntrepriseSection}
          >
            <Text
              variant={TextVariant.text1}
              className={twMerge(
                "font-poppins text-lg capitalize",
                section === "company" ? "text-primaryDark" : "text-greyDark"
              )}
            >
              Entreprises
            </Text>
          </div>
          <div
            className="flex items-center justify-center w-1/2 h-12"
            onMouseEnter={displaySchoolSection}
            onClick={displaySchoolSection}
          >
            <Text
              variant={TextVariant.text1}
              className={twMerge(
                "font-poppins text-lg capitalize",
                section === "school" ? "text-primaryDark" : "text-greyDark"
              )}
            >
              Écoles
            </Text>
          </div>
        </div>
        <div
          className={twMerge(
            "h-1 flex",
            section === "company" ? "" : "justify-end"
          )}
        >
          <div className="w-1/2 h-1 bg-primary" />
        </div>
      </div>

      {section === "company" ? (
        <div className="grid grid-cols-2 gap-2 lg:gap-8 lg:grid-cols-4 mt-main">
          <div className={imageWrapperClassName}>
            <StaticImage
              objectFit="contain"
              className={imageClassName}
              alt="logo bouygues"
              src="../../images/logos/bouygues.png"
            />
          </div>
          <div className={imageWrapperClassName}>
            <StaticImage
              objectFit="contain"
              className={imageClassName}
              alt="logo pole emploi"
              src="../../images/logos/pole-emploi.png"
            />
          </div>
          <div className={imageWrapperClassName}>
            <StaticImage
              objectFit="contain"
              className={imageClassName}
              alt="logo air france"
              src="../../images/logos/air-france.png"
            />
          </div>
          <div className={imageWrapperClassName}>
            <StaticImage
              objectFit="contain"
              className={imageClassName}
              alt="logo caisse d epargne"
              src="../../images/logos/caisse-depargne.png"
            />
          </div>
          {Boolean(fullList || isDesktopOrLaptop) ? (
            <>
              <div className={imageWrapperClassName}>
                <StaticImage
                  objectFit="contain"
                  className={imageClassName}
                  alt="logo laguiole"
                  src="../../images/logos/laguiole.jpg"
                />
              </div>
              <Link
                to="/client/cadeau-maestro"
                title="cliquez pour voir le use case"
                className={twMerge(imageWrapperClassName, "relative")}
              >
                <StaticImage
                  objectFit="contain"
                  className={imageClassName}
                  alt="logo cadeau maestro"
                  src="../../images/logos/cadeau-maestro.jpg"
                />
                <div className="absolute bottom-0 w-full rounded-b-2xl h-9 bg-gradient-to-b from-transparent to-primary">
                  <p className="leading-9 tracking-wide text-center text-white font-poppins">
                    Étude de cas &gt;
                  </p>
                </div>
              </Link>
              <div className={imageWrapperClassName}>
                <StaticImage
                  objectFit="contain"
                  className={imageClassName}
                  alt="logo heetch"
                  src="../../images/logos/heetch.png"
                />
              </div>
              <div className={imageWrapperClassName}>
                <StaticImage
                  objectFit="contain"
                  className={imageClassName}
                  alt="logo la french tech"
                  src="../../images/logos/french-tech.png"
                />
              </div>
              <div className={imageWrapperClassName}>
                <StaticImage
                  objectFit="contain"
                  className={imageClassName}
                  alt="logo combigo"
                  src="../../images/logos/combigo.png"
                />
              </div>
              <div className={imageWrapperClassName}>
                <StaticImage
                  objectFit="contain"
                  className={imageClassName}
                  alt="logo zooparc de beauval"
                  src="../../images/logos/zooparc-beauval.png"
                />
              </div>
              <div className={imageWrapperClassName}>
                <StaticImage
                  objectFit="contain"
                  className={imageClassName}
                  alt="logo tbwa groupe"
                  src="../../images/logos/tbwa-groupe.png"
                />
              </div>
              <div className={imageWrapperClassName}>
                <StaticImage
                  objectFit="contain"
                  className={imageClassName}
                  alt="logo adrenalib"
                  src="../../images/logos/adrenalib.png"
                />
              </div>

              <Link
                to="/client/inventiv-it"
                title="cliquez pour voir le use case"
                className={twMerge(imageWrapperClassName, "relative")}
              >
                <StaticImage
                  objectFit="contain"
                  className={imageClassName}
                  alt="logo inventiv-it"
                  src="../../images/logos/inventiv-it.png"
                />
                <div className="absolute bottom-0 w-full rounded-b-2xl h-9 bg-gradient-to-b from-transparent to-primary">
                  <p className="leading-9 tracking-wide text-center text-white font-poppins">
                    Étude de cas &gt;
                  </p>
                </div>
              </Link>
              <div
                className={twMerge(imageWrapperClassName, "hidden lg:block")}
              >
                <StaticImage
                  objectFit="contain"
                  className={imageClassName}
                  alt="logo de l'école multimédia"
                  src="../../images/logos/ecole-multimedia.png"
                />
              </div>
            </>
          ) : null}
        </div>
      ) : (
        // school
        <div className="grid grid-cols-2 gap-2">
          <div className={imageWrapperClassName}>
            <StaticImage
              objectFit="contain"
              className={imageClassName}
              alt="logo de l'école multimédia"
              src="../../images/logos/ecole-multimedia.png"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerList;
